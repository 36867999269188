<template>
  <image-upload-test/>
</template>

<script>
import ImageUploadTest from '@/components/Upload.vue';

export default {
  name: 'ImageUpload',
  components: {
    ImageUploadTest,
  },
};

</script>
