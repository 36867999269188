var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"blogEntries"},[(_vm.loading)?_c('div',{staticClass:"container margin-auto"},[_c('div',{staticClass:"flex-container-full dir-col text-center"},[_c('TheLoader')],1)]):_vm._e(),_c('MarqueeLoader',{ref:"marquee",attrs:{"isLoading":_vm.loading}}),(!_vm.loading && _vm.firstBlog)?_c('div',{staticClass:"container margin-auto"},[_c('div',{staticClass:"flex-container-full flex-center"},[_c('h1',{},[_vm._v("Latest Articles")])]),_c('div',{staticClass:"flex-container-full flex-center"},[_c('div',{staticClass:"row featured-entry justify-center"},[(_vm.firstBlog)?_c('router-link',{staticClass:"entry entry-lg",attrs:{"to":{ name: 'BlogShow',
       params: {id: _vm.firstBlog._id,
       title: _vm.firstBlog.title,
       slug: _vm.firstBlog.slug} }}},[_c('div',{staticClass:"entry-header",style:({ backgroundImage: `url(${_vm.firstBlog.featuredImageLocation})` })}),_c('h3',{staticClass:"blog-title"},[_vm._v(" "+_vm._s(_vm.firstBlog.title)+" ")]),_c('span',[_c('p',[_vm._v(_vm._s(_vm.dateFormat(_vm.firstBlog.createdAt)))]),_c('div',{staticClass:"rating-container"},[_c('Heart'),_c('span',[_vm._v(_vm._s(_vm.firstBlog.rating))])],1)])]):_vm._e()],1),_c('div',{staticClass:"flex-container-sm"},_vm._l((_vm.blogs),function(item){return _c('router-link',{key:item.title,staticClass:"entry entry-sm featured",attrs:{"to":{ name: 'BlogShow', params: {id: item._id,
      title: _vm.addHyphens(item.title),
      slug: item.slug} }}},[_c('div',{staticClass:"detail-container"},[_c('h3',{staticClass:"blog-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))]),_c('div',{staticClass:"rating-container"},[_c('Heart'),_c('span',[_vm._v(_vm._s(item.rating))])],1)]),_c('div',{staticClass:"blob"})])}),1)]),_c('div',{staticClass:"flex-container-full flex-left"},[_c('div',{staticClass:"row old-entries dir-col"},_vm._l((_vm.remainingBlogs),function(blog){return _c('router-link',{key:blog.title,staticClass:"old-blog",attrs:{"to":{ name: 'BlogShow', params: {id: blog._id,
      title: _vm.addHyphens(blog.title),
      slug: blog.slug} }}},[_c('div',{staticClass:"detail-container"},[_c('p',{staticClass:"old-blog-date"},[_vm._v(_vm._s(_vm.dateFormat(blog.createdAt)))]),_c('h3',{staticClass:"old-blog-title primary-btn-link"},[_vm._v(" "+_vm._s(blog.title)+" ")])])])}),1)])]):_vm._e(),(!_vm.firstBlog && !_vm.loading)?_c('div',{staticClass:"container margin-auto"},[_c('div',{staticClass:"flex-container-full dir-col text-center"},[_c('h1',{},[_vm._v("Wow, Much Empty")]),_c('img',{staticClass:"no-results-img margin-auto",attrs:{"src":require("../../public/assets/imgs/doge-pixel.png"),"alt":"pixel doge image"}})])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }