<template>
  <Login>
    <template v-slot:header>
      <h1>Login</h1>
    </template>
  </Login>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  components: {
    Login,
  },
};
</script>

<style>

</style>
