<template>
  <div class="container-full site-content">
    <div class="container">
      <div class="row justify-center dir-col">
        <div class="col-xlarge-12">
          <h1>You've successfully logged out.</h1>
        </div>
        <div class="col-xlarge-12">
          <button class="primary-btn-link">
            <router-link
            to="/"
            >
            Home
            </router-link>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
