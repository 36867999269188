var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"nav-container"}},[_c('div',{staticClass:"nav"},[_c('section',{staticClass:"header"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"navImg",attrs:{"src":require("@/assets/code-nav.svg"),"alt":""}})]),_c('div',{staticClass:"hamburger-box"},[_c('img',{ref:"hamburger",staticClass:"hamburger",attrs:{"src":require("@/components/svgs/icons8-hamburger-96.png"),"alt":""},on:{"click":_vm.expandNav}})])],1),_c('ul',{ref:"nav",staticClass:"nav-links"},[_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Blog")])],1),_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About")])],1),_c('li',{staticClass:"links"},[_c('a',{on:{"click":_vm.expandSearch}},[_vm._v("Search")])]),_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/projects"}},[_vm._v("Projects")])],1),_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/random"}},[_vm._v("Random")])],1),(this.$store.state.isUserLoggedIn)?_c('li',{staticClass:"links",on:{"click":_vm.logout}},[_vm._v(" Logout ")]):_vm._e(),(this.$store.state.isUserLoggedIn)?_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/drafts"}},[_vm._v("Drafts")])],1):_vm._e(),_c('li',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v(" Contact ")])],1)]),(this.$store.state.isUserLoggedIn && this.$store.state.user.profileImageLocation)?_c('div',{staticClass:"user-initial",style:({backgroundImage: `url(${_vm.getUserProfileImage()})`})},[_c('router-link',{attrs:{"to":"/user"}})],1):(this.$store.state.isUserLoggedIn &&
      !this.$store.state.user.profileImageLocation)?_c('div',{staticClass:"user-initial"},[_c('router-link',{attrs:{"to":"/user"}},[_vm._v(_vm._s(_vm.printUser()))])],1):_vm._e()]),_c('div',{ref:"searchContainer",staticClass:"search-container"},[_c('div',{staticClass:"container-full"},[_c('div',{staticClass:"row dir-row no-wrap"},[_c('i',{staticClass:"fas fa-search"}),_c('input',{ref:"searchBar",staticClass:"search",attrs:{"placeholder":"search posts","type":"text","name":"","value":""},on:{"input":_vm.testSearch}})])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.searching)?_c('div',{staticClass:"search-results"},[_c('Loader')],1):(_vm.searchResults !== null && _vm.searchResults !== undefined)?_c('div',{staticClass:"search-results"},[_c('div',{staticClass:"row old-entries dir-col"},_vm._l((_vm.searchResults),function(post){return _c('router-link',{key:post._id,attrs:{"to":{
        name: 'BlogShow',
        params: {
          id: post._id,
          slug: post.slug
          }
        }}},[_c('div',{staticClass:"detail-container"},[_c('p',{staticClass:"old-blog-date"},[_vm._v(_vm._s(_vm.dateFormat(post.createdAt)))]),_c('h3',{staticClass:"old-blog-title primary-btn-link"},[_vm._v(" "+_vm._s(post.title)+" ")])])])}),1)]):(_vm.searchResults === undefined)?_c('div',{staticClass:"search-results"},[_c('p',[_vm._v("Wow, Much Empty")]),_c('img',{staticClass:"no-results-img",attrs:{"src":require("../../public/assets/imgs/doge-pixel.png"),"alt":"pixel doge image"}})]):_c('div',{staticClass:"search-results"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }